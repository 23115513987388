/* Layout */
@mixin svar-mq display, --d;
@mixin svar-mq float, --ft;
@mixin svar-mq position, --pos;
@mixin svar-mq top, --top;
@mixin svar-mq left, --left;
@mixin svar-mq right, --right;
@mixin svar-mq bottom, --bottom;
@mixin svar-mq object-fit, --objf;
@mixin svar-mq object-position, --objp;
@mixin svar-mq visibility, --v;
@mixin svar-mq z-index, --z;
