:root {
  --white: #fff;
  --grey: #e6e6e6;
  --black: #262626;
  --green: #06847d;
  --blue: #4a69bd;
  --orange: #a46313;
  --red: #e02e06;
  --primary: var(--blue);
  --secondary: var(--green);
  --background: #fff;
  --background-alt: #f8fafc;
  --selection: #9e9e9e;
  --text-main: #4a4a4a;
  --text-bright: #000;
  --text-muted: #707070;
  --links: var(--primary);
  --focus: rgba(74, 105, 189, .35);
  --border: rgba(0, 0, 0, .2);
  --border-radius: 4px;
  --code: #000;
  --animation-duration: .1s;
  --button-hover: #ddd;
  --button-shadow: 0 4px 6px -1px rgba(0, 0, 0, .1), 0 2px 4px -1px rgba(0, 0, 0, .06);
  --form-placeholder: #949494;
  --form-text: #000;
  --variable: #39a33c;
  --highlight: #ff0;
  --select-arrow: svg-load("./assets/select-arrow.svg", fill: #161f27);
  --heading-margin: .5rem 0 .75rem;
  --typo-size-1: 2.8rem;
  --typo-size-2: 2.2rem;
  --typo-size-3: 1.8rem;
  --typo-size-4: 1.4rem;
  --typo-size-5: 1.2rem;
  --typo-size-6: 1rem;

  --wrapper: 1280px;

  --transition: all ease .4s;

  --accent-border: 0;
  --accent-shadow: 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -2px rgba(0, 0, 0, .05);
}
