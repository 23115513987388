/* Flex */
@mixin svar-mq flex, --fx;
@mixin svar-mq flex-direction, --fd;
@mixin svar-mq flex-wrap, --fw;
@mixin svar-mq align-content, --ac;
@mixin svar-mq align-items, --ai;
@mixin svar-mq align-self, --as;
@mixin svar-mq justify-content, --jc;
@mixin svar-mq flex-grow, --fg;
@mixin svar-mq flex-shrink, --fs;
@mixin svar-mq flex-basis, --fb;
@mixin svar-mq order, --o;
