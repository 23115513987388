/* Background */
@mixin svar-mq background, --bg;
@mixin svar-mq background-attachment, --bga;
@mixin svar-mq background-color, --bgc;
@mixin svar-mq background-image, --bgi;
@mixin svar-mq background-position, --bgp;
@mixin svar-mq background-repeat, --bgr;
@mixin svar-mq background-size, --bgs;

@mixin svar:hover background, --hvr-bg;
@mixin svar:hover background-color, --hvr-bgc;

[style*="--grad-top:"] {
    background-image: linear-gradient(0deg, transparent, var(--grad-bottom));
}

[style*="--grad-bottom:"] {
    background-image: linear-gradient(180deg, transparent, var(--grad-bottom));
}