/* Transform Helpers */

[style*="--translate:"],
[style*="--translatex:"],
[style*="--translatey:"],
[style*="--scale:"],
[style*="--rotate:"],
[style*="--skew:"] {
  transform: 
    translate(var(--translate, 0)) 
    translateX(var(--translatex, 0)) 
    translateY(var(--translatey, 0)) 
    scale(var(--scale, 100%)) 
    rotate(var(--rotate, 0)) 
    skew(var(--skew, 0));
}

[style*="--translate-hvr:"],
[style*="--translatex-hvr:"],
[style*="--translatey-hvr:"],
[style*="--scale-hvr:"],
[style*="--rotate-hvr:"],
[style*="--skew-hvr:"] {
  transition: var(--transition);

  &:hover {
    transform: 
      translate(var(--translate-hvr, 0)) 
      translateX(var(--translatex-hvr, 0)) 
      translateY(var(--translatey-hvr, 0))
      scale(var(--scale-hvr, 100%)) 
      rotate(var(--rotate-hvr, 0))
      skew(var(--skew-hvr, 0));
  }
}

