/* Layout Helpers */

.wrapper,
[style*="--wrapper:"] {
  max-width: var(--wrapper);
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--gutter);
  padding-right: var(--gutter);

  @media only screen and (min-width:640px) {
    --gutter: var(--gutter-sm);
  }
  @media only screen and (min-width:768px) {
    --gutter: var(--gutter-md);
  }
  @media only screen and (min-width:1024px) {
    --gutter: var(--gutter-lg);
  }
  @media only screen and (min-width:1280px) {
    --gutter: var(--gutter-xl);
  }
}