/* Border */
@mixin svar-mq border, --b;
@mixin svar-mq border-color, --bc;
@mixin svar-mq border-style, --bs;
@mixin svar-mq border-width, --bw;
@mixin svar-mq border-radius, --br;
@mixin svar-mq border-radius, --radius;
@mixin svar-mq border-top, --bt;
@mixin svar-mq border-left, --bl;
@mixin svar-mq border-right, --br;
@mixin svar-mq border-bottom, --bb;
@mixin svar-mq border-top-left-radius, --btlr;
@mixin svar-mq border-top-right-radius, --btrr;
@mixin svar-mq border-bottom-left-radius, --bblr;
@mixin svar-mq border-bottom-right-radius, --bbrr;

@mixin svar:hover border, --hvr-b;
@mixin svar:hover border-color, --hvr-bc;
