/* Typography Helpers */

[style*="--line-clamp:"] {
    display: -webkit-box;
    -webkit-line-clamp: var(--line-clamp);
    -webkit-box-orient: vertical;  
    overflow: hidden;
}

[style*="--text-3d:"] {
    text-shadow: 6px 6px 10px   rgba(255,255,255,.1),
                 6px 6px        var(--text-3d), 
                 5.75px 5.75px  var(--text-3d), 
                 5.5px 5.5px    var(--text-3d), 
                 5.25px 5.25px  var(--text-3d),
                 5px 5px        var(--text-3d), 
                 4.75px 4.75px  var(--text-3d), 
                 4.5px 4.5px    var(--text-3d), 
                 4.25px 4.25px  var(--text-3d),
                 4px 4px        var(--text-3d),
                 3.75px 3.75px  var(--text-3d),
                 3.5px 3.5px    var(--text-3d),
                 3.25px 3.25px  var(--text-3d),
                 3px 3px        var(--text-3d),
                 2.75px 2.75px  var(--text-3d),
                 2.5px 2.5px    var(--text-3d),
                 2.25px 2.25px  var(--text-3d),
                 2px 2px        var(--text-3d),
                 1.75px 1.75px  var(--text-3d),
                 1.5px 1.5px    var(--text-3d),
                 1.25px 1.25px  var(--text-3d),
                 1px 1px        var(--text-3d),
                 .75px .75px    var(--text-3d),
                 .5px .5px      var(--text-3d),
                 .25px .25px    var(--text-3d);
}
