/* Spacing Helpers */

[style*="--px:"] {
    padding-inline-start: var(--px);
    padding-inline-end: var(--px);
}

[style*="--py:"] {
    padding-block-start: var(--py);
    padding-block-end: var(--py);
}

[style*="--mx:"] {
    margin-inline-start: var(--mx);
    margin-inline-end: var(--mx);
}

[style*="--my:"] {
    margin-block-start: var(--my);
    margin-block-end: var(--my);
}  